import React, { FC, ReactNode } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { CatalogServiceDto, ImageDto } from '@wix/bookings-uou-types';
import { ServiceListLayoutOptions } from '../../../../../types/types';
import { ReferralInfo } from '../../../../../utils/bi/consts';
import { useWidgetActions } from '../../hooks/useWidgetActions';
import settingsParams from '../../../settingsParams';
import { Card } from './Card/Card';
import { Strip } from './Strip/Strip';
import { Overlapping } from './Overlapping/Overlapping';
import { ServiceInfo } from './ServiceInfo/ServiceInfo';
import { ServiceImage } from './ServiceImage/ServiceImage';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';

export type ServiceCardProps = {
  service: CatalogServiceDto;
  setBoundingClientRect?: Function;
  width: number;
  height: number;
};

export interface ServiceLayoutProps {
  image: ReactNode;
  info: ReactNode;
}

function getCardComponent({
  serviceListLayout,
}: {
  serviceListLayout: ServiceListLayoutOptions;
}) {
  switch (serviceListLayout) {
    case ServiceListLayoutOptions.OVERLAPPING:
      return Overlapping;
    case ServiceListLayoutOptions.STRIP:
      return Strip;
    case ServiceListLayoutOptions.GRID:
    case ServiceListLayoutOptions.CLASSIC:
    default:
      return Card;
  }
}

export const ServiceCard: FC<ServiceCardProps> = (props) => {
  const { service, setBoundingClientRect, width, height } = props;
  const settings = useSettings();
  const { serviceListLayout } = useWidgetViewModel();

  const CardComponent = getCardComponent({ serviceListLayout });
  const { navigateToServicePage } = useWidgetActions();

  const getImage = (): ImageDto | null => {
    const image = service.info.images && service.info.images[0];
    return image ? image : null;
  };

  const isServiceImageVisible = settings.get(
    settingsParams.isServiceImageVisible,
  );

  return (
    <CardComponent
      info={<ServiceInfo service={service} />}
      image={
        isServiceImageVisible ? (
          <ServiceImage
            image={getImage()}
            onClick={() =>
              navigateToServicePage(service, ReferralInfo.SERVICE_IMAGE)
            }
            imageTitle={service.info.name}
            setBoundingClientRect={setBoundingClientRect}
            width={width}
            height={height}
          />
        ) : null
      }
    />
  );
};
