import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';
import { classes, st } from './Title.st.css';
import { DataHooks } from './consts';
import stylesParams from '../../../../../stylesParams';

export type TitleProps = {
  title: string;
  url?: string;
  onClick: () => void;
};

export const Title: FC<TitleProps> = ({ title, url, onClick }) => {
  const { isMobile, isViewer } = useEnvironment();
  const styles = useStyles();

  const getHrefAttribute = () => (isViewer ? { href: url } : {});

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={DataHooks.ROOT}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <a
        {...getHrefAttribute()}
        data-hook={DataHooks.LINK}
        className={classes.link}
        role="link"
        tabIndex={0}
        aria-label={title}
      >
        <Text
          className={classes.title}
          data-hook={DataHooks.TEXT}
          typography={TYPOGRAPHY.smallTitle}
          tagName={styles.get(stylesParams.serviceNameFont).htmlTag}
        >
          {title}
        </Text>
      </a>
    </div>
  );
};
