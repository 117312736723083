import React, { FC } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { StripCard } from 'wix-ui-tpa';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { ServiceLayoutProps } from '../ServiceCard';
import { ImageShapeOptions } from '../../../../../../types/types';
import settingsParams from '../../../../settingsParams';
import { classes, st } from './Strip.st.css';
import { DataHooks } from '../consts';

export const Strip: FC<ServiceLayoutProps> = (props) => {
  const { image, info } = props;
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const settings = useSettings();

  return (
    <StripCard
      data-hook={DataHooks.STRIP}
      media={image}
      info={info}
      className={st(classes.root, {
        isMobile,
        responsive: experiments.enabled(
          'specs.bookings.StripLayoutImprovements',
        ),
        imageSize: settings.get(settingsParams.serviceImageSize),
      })}
      roundMedia={
        settings.get(settingsParams.serviceImageShape) ===
        ImageShapeOptions.ROUND
      }
    />
  );
};
