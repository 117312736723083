import React, { FC, useEffect } from 'react';
import {
  useEnvironment,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { EmptyState } from './EmptyState/EmptyState';
import { Header } from './Header/Header';
import { Body } from './Body/Body';
import { Dialog } from './Dialog/Dialog';
import { HiddenServicesForSeo } from './HiddenServicesForSeo/HiddenServicesForSeo';
import { WidgetViewModelProvider } from './hooks/useWidgetViewModel';
import { WidgetActionsProvider } from './hooks/useWidgetActions';
import { WidgetViewModel } from '../../../viewModel/viewModel';
import { WidgetActions } from '../../../actions/actions';
import { classes, st } from './Widget.st.css';
import {
  DESKTOP_WIDGET_MIN_WIDTH,
  MOBILE_WIDGET_MIN_WIDTH,
} from '../../../consts';

export type ControllerProps = {
  widgetViewModel: WidgetViewModel;
  widgetActions: WidgetActions;
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetViewModel, widgetActions } = props;
  const { headerViewModel, services, seo } = widgetViewModel;
  const {
    isRTL,
    isEditor,
    isMobile,
    isADI,
    dimensions: { width, height },
  } = useEnvironment();
  const { t } = useTranslation();

  useEffect(() => {
    const widgetMinWidth = isMobile
      ? MOBILE_WIDGET_MIN_WIDTH
      : DESKTOP_WIDGET_MIN_WIDTH;

    if (
      isEditor &&
      !isADI &&
      typeof width === 'number' &&
      width < widgetMinWidth
    ) {
      props.host.resizeComponent &&
        props.host.resizeComponent({
          width: widgetMinWidth,
          height,
          mobileResize: isMobile,
        });
    }
  }, [isEditor, isMobile, props.host, width, height]);

  useEffect(() => {
    props.widgetActions.getCourseAvailability();
  }, []);

  return (
    <div data-hook="widget" className={st(classes.root, { isRTL, isMobile })}>
      <WidgetViewModelProvider value={widgetViewModel}>
        <WidgetActionsProvider value={widgetActions}>
          {services.length ? (
            <>
              <Dialog
                isOpen={widgetViewModel.showDialog}
                onClose={() => widgetActions.onDialogClose()}
                content={t('user-message.not-bookable')}
                confirmationButtonLabel={t('user-message.action-ok-label')}
              />
              {headerViewModel ? <Header /> : null}
              <Body />
              {seo && seo.shouldListServicesFromOtherCategories ? (
                <HiddenServicesForSeo />
              ) : null}
            </>
          ) : (
            <EmptyState />
          )}
        </WidgetActionsProvider>
      </WidgetViewModelProvider>
    </div>
  );
};
