import React, { FC } from 'react';
import { Dropdown } from 'wix-ui-tpa';
import { classes } from './DropdownFilter.st.css';
import { DataHooks } from '../consts';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { useWidgetActions } from '../../../hooks/useWidgetActions';

export const DropdownFilter: FC = () => {
  const { filterOptions } = useWidgetViewModel();
  const { onFilterOptionSelected } = useWidgetActions();

  const options = filterOptions.map(({ title }, index) => ({
    id: `${index}`,
    isSelectable: true,
    value: title,
  }));

  const initialSelectedId = filterOptions
    .findIndex(({ isSelected }) => isSelected)
    .toString();

  return (
    <Dropdown
      data-hook={DataHooks.DropDown}
      className={classes.root}
      initialSelectedId={initialSelectedId}
      options={options}
      onChange={({ id }) => {
        onFilterOptionSelected(filterOptions[Number(id)]);
      }}
    />
  );
};
