import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Text } from 'wix-ui-tpa';
import { classes, st } from './TagLine.st.css';
import { DataHooks, MAX_TAGLINE_LENGTH } from './consts';
import stylesParams from '../../../../../stylesParams';

export type TagLineProps = {
  tagLine: string;
};

export const TagLine: FC<TagLineProps> = ({ tagLine }) => {
  const { isMobile } = useEnvironment();
  const styles = useStyles();

  return (
    <div
      tabIndex={-1}
      data-hook={DataHooks.ROOT}
      className={st(classes.root, { isMobile })}
    >
      <Text
        data-hook={DataHooks.TEXT}
        className={classes.text}
        tagName={styles.get(stylesParams.serviceTagLineFont).htmlTag}
      >
        {shortenText(tagLine, MAX_TAGLINE_LENGTH)}
      </Text>
    </div>
  );
};

const shortenText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};
