import React, { FC } from 'react';
import { OverlappingCard } from 'wix-ui-tpa';
import { ServiceLayoutProps } from '../ServiceCard';
import { classes } from './Overlapping.st.css';
import { DataHooks } from '../consts';
import { useInvertInfoPosition } from '../../../hooks/useInvertInfoPosition';

export const Overlapping: FC<ServiceLayoutProps> = (props) => {
  const { image, info } = props;
  const invertInfoPosition = useInvertInfoPosition();

  return (
    <OverlappingCard
      data-hook={DataHooks.OVERLAPPING}
      media={image}
      info={info}
      className={classes.root}
      invertInfoPosition={invertInfoPosition}
    />
  );
};
