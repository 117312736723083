import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  Button,
  ButtonPriority as PRIORITY,
  ButtonSize as SIZE,
} from 'wix-ui-tpa';
import { classes, st } from './BookButton.st.css';
import settingsParams from '../../../../../settingsParams';
import { DataHooks } from './consts';
import { ButtonStyleOptions } from '../../../../../../../types/types';

export interface BookButtonProps {
  onClick: () => void;
  isNoBookFlow: boolean;
  isPendingApprovalFlow: boolean;
  showViewCourse?: boolean;
}

export const BookButton: FC<BookButtonProps> = ({
  onClick,
  isNoBookFlow,
  isPendingApprovalFlow,
  showViewCourse,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const theme = settings.get(settingsParams.bookButtonStyle);
  const isSecondary =
    theme === ButtonStyleOptions.SQUARE_HOLE ||
    theme === ButtonStyleOptions.CIRCLE_HOLE;

  const getContent = () => {
    if (showViewCourse) {
      return (
        settings.get(settingsParams.viewCourseButtonText) ||
        t('info-card.design.button.view-course')
      );
    }
    if (isNoBookFlow) {
      return (
        settings.get(settingsParams.noBookFlowText) ||
        t('info-card.design.button.more-info')
      );
    }
    if (isPendingApprovalFlow) {
      return (
        settings.get(settingsParams.pendingApprovalText) ||
        t('info-card.design.button.pending-approval')
      );
    }
    return (
      settings.get(settingsParams.bookButtonText) ||
      t('info-card.design.button.book-it')
    );
  };

  return (
    <div
      data-hook={DataHooks.ROOT}
      className={st(classes.root, {
        theme,
        isMobile,
      })}
    >
      <Button
        data-hook={DataHooks.BUTTON}
        className={st(classes.button)}
        size={SIZE.medium}
        priority={isSecondary ? PRIORITY.secondary : PRIORITY.primary}
        onClick={onClick}
        fullWidth={isMobile}
        aria-label={getContent()}
        role="button"
        tabIndex={0}
      >
        {getContent()}
      </Button>
    </div>
  );
};
