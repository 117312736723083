import React, { FC } from 'react';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { DataHooks } from './consts';
import { st, classes } from './Title.st.css';
import stylesParams from '../../../stylesParams';
import settingsParams from '../../../settingsParams';

export type TitleProps = {};

export const Title: FC = () => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <div
      data-hook={DataHooks.WRAPPER}
      className={st(classes.root, {
        alignment: settings.get(settingsParams.titleAlignment),
        isMobile,
      })}
    >
      <Text
        className={classes.title}
        data-hook={DataHooks.TITLE}
        typography={TYPOGRAPHY.largeTitle}
        tagName={styles.get(stylesParams.widgetTitleFont).htmlTag}
      >
        {settings.get(settingsParams.titleText) || t('multi-offerings.title')}
      </Text>
    </div>
  );
};
