import { ImagePositionOptions } from '../../../../types/types';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import settingsParams from '../../settingsParams';

export const useInvertInfoPosition = (): boolean => {
  const settings = useSettings();
  const { isRTL } = useEnvironment();
  return (
    settings.get(settingsParams.serviceImagePosition) ===
    ((isRTL && ImagePositionOptions.LEFT) || ImagePositionOptions.RIGHT)
  );
};
